<template>
  <v-toolbar v-if="!hideToolbar"  max-height="64px" class="app-bar" color="white" elevation="0">
    <div class="strip strip-1 d-none d-sm-flex"></div>
    <div class="strip strip-2 d-none d-sm-flex"></div>
    <div class="strip strip-3 d-none d-sm-flex"></div>
    <div class="strip strip-4 d-none d-sm-flex"></div>
    <div class="strip strip-5 d-none d-sm-flex"></div>
    <v-toolbar-title 
      :class="{ 'd-none': !hideAction , 'd-lg-flex':!hideAction}"
    >
      <img
        src="@/assets/enmologo.svg"
        style="height:48px;"
        class="mt-2 mr-4"
        alt="Smart Energy Consumption Analytics"
      />
      </v-toolbar-title>
      <v-toolbar-title 
      :class="{ 'd-none': !hideAction , 'd-md-flex':!hideAction, 'text-color':true}"
    >
      Smart Energy Consumption Analytics
    </v-toolbar-title>
    <v-spacer class="d-none d-md-flex"></v-spacer>

    <v-toolbar-title v-if="showOrg">
      <v-select
        class="mt-6 mr-2"
        v-model="siteSelected"
        :items="sites"
        item-value="_id" 
        item-text="name"
        prepend-inner-icon="mdi-city"
        dense
        outlined
        background-color="rgba(255,255,255,0.85)"
        style="max-width:300px;"
        color="#472f92"
        :disabled="isChangingSite"
        @change="onSiteChange($event)"
      ></v-select>
      </v-toolbar-title>
      <v-toolbar-title v-if="!hideAction">
        <v-menu 
          left 
          bottom 
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          origin="right top"
          offset-y
          min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs" v-on="on"
            class="calendar mr-6 mr-sm-1"
            rounded
            style="min-width:40px;height:40px;"
            outlined
            elevation="0"
            :color="selectedDate?'rgba(71,47,146, 0.9)':'rgba(0, 0, 0, 0.6)'"
          >
          <v-icon>mdi-calendar</v-icon><span :class="{'ml-2':selectedDate, 'd-none':true, 'd-sm-block':true}">{{ selectedDate?formatedFullDate(selectedDate):""}}</span>
          </v-btn>
        </template>
      <v-date-picker v-model="date"
        color="#472f92"
        header-color="#00b490"
        scrollable
        first-day-of-week=1
        :show-current="currentDate"
      >
      <v-btn
        v-if="date !== (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
            text
            color="#472f92"
            @click="date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10); selectDate()"
          >
            TODAY
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="#472f92"
            @click="selectDate"
            :disabled="disabledSelectDate"
          >
            SELECT
          </v-btn>
      </v-date-picker>
      </v-menu>
      </v-toolbar-title>
      <v-spacer class="d-flex d-md-none"></v-spacer>
    <v-menu v-if="!hideAction" left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" fab small>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
          <v-list>
            <v-list-item  @click="goToWattic">
              <v-list-item-title>Analytic Engine <v-icon small>mdi-launch</v-icon>
            </v-list-item-title>
            </v-list-item>
            <v-list-item  @click="dialog = true">
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logOut">
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
    </v-menu>
    <v-dialog v-model="dialog" width="500">
        <v-form @submit.prevent="changePassword" ref="form" lazy-validation >
          <v-card class="pa-2">
            <v-card-title class="headline">Change Password</v-card-title>
            
            <v-card-text>
              <v-alert dense type="error" text v-show="apiError">
                {{apiError}}
              </v-alert>
              <v-text-field
                v-model="currentPassword"
                :rules="passwordRules"
                :disabled="isLoading"
                label="Current Password"
                auto-grow
                required
                :type="showCurrentPassword ? 'text' : 'password'"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                color="#00b490"
              />
              <v-text-field
                v-model="newPassword"
                :rules="passwordRules"
                :disabled="isLoading"
                label="New Password"
                auto-grow
                required
                :type="showNewPassword ? 'text' : 'password'"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showNewPassword = !showNewPassword"
                color="#00b490"
              />
              <v-text-field
                v-model="confirmPassword"
                :rules="confirmPasswordRules"
                :disabled="isLoading"
                label="Confirm Password"
                auto-grow
                required
                :type="showConfirmPassword ? 'text' : 'password'"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                color="#00b490"
              />
            </v-card-text>
            <v-card-actions >
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                depressed
                dark
                color="#472f92"
                :loading="isLoading"
              >
                Submit
              </v-btn>
              <v-btn
                text
                depressed
                @click="close"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-form>
         </v-dialog>
  </v-toolbar>
</template>
<script>
import {ProfileService} from "../services"
import {DateMixin} from '../DateMixin.js'

export default {
  mixins:[DateMixin],
  data() {
    return {
      isChangingSite:false,
      sites: [],
      siteSelected:{},
      dialog: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be 8 characters or more",
      ],
      isLoading: false,
      hideToolbar: false,
      apiError:"",
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      selectedDate: undefined,
      menu:false,
      disabledSelectDate: false
    };
  },
  computed: {
    hideAction() {
      return this.$store.state.user.accessToken == null;
    },
    confirmPasswordRules() {
      return [
        (value) => !!value || "Please type confirm password",
        (value) =>
          value === this.newPassword ||
          "The password confirmation does not match",
      ];
    },
    showOrg() {
      return this.sites && this.sites.length > 0
    }
  },
  watch:{
    async hideAction() {
      if ( this.hideAction ) {
          this.sites = []
      }else{
        this.showSites()
      }
    },
    date() {
      const maxDate = new Date()
      const selectDate = new Date(this.date+" 00:00:00")
      this.disabledSelectDate = maxDate.getTime() < selectDate.getTime()
      this.currentDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }
  },
  methods: {
    selectDate() {
      this.selectedDate = new Date(this.date+" 00:00:00")
      const today = new Date()
      today.setHours(0,0,0,0)
      if ( this.selectedDate.getTime() === today.getTime() ){
        this.$refs.menu.save((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
        this.selectedDate = undefined
      }else{
        this.$refs.menu.save(this.date)
      }
      this.$emit('dateChanged', this.selectedDate)
    },
    async onSiteChange() {
      this.isChangingSite = true
      await ProfileService.switchSite({siteId:this.siteSelected})
      this.isChangingSite = false

      this.$emit('siteChanged',this.siteSelected)
    },
    async changePassword() {
      this.isLoading = true
      const res = await ProfileService.changePassword({
        oldPassword: this.currentPassword,
        newPassword: this.newPassword,
      });
      this.isLoading = false
      if ( res.code === 0 ) {
        this.apiError = ""
        this.newPassword = ""
        this.confirmPassword = ""
        this.currentPassword = ""
        this.$refs.form.reset()
        this.dialog = false
      }else if (res.message) {
        this.apiError = res.message
      }
      
    },
    goToWattic(){
      window.open('https://enmo.banpunext.co.th', '_blank')
    },
    close(){
      this.$refs.form.reset()
      this.dialog = false
    },
    async logOut() {
      await this.$store.dispatch("user/logout");
      this.$router.push("/login");
    },
    async showSites() {
      const profile = await ProfileService.profile()
      if ( profile && profile.data && profile.data.user && profile.data.user.sites ) {
        this.sites = profile.data.user.sites
        this.siteSelected = profile.data.session.site
        this.$emit('siteChanged',this.siteSelected)
      }else{
        this.sites = []
      }
    }
  },
  mounted() {
    if ( this.$store.state.user.accessToken != null ){
      this.showSites()
    }
    this.hideToolbar = this.$router.currentRoute.path === "/wattic"
  }
};
</script>

<style scoped>
.calendar {
  background: rgba(255, 255, 255, 0.7);
}
.app-bar {
  /* background: linear-gradient(to right, #00b490, #472f92) padding-box; */
      /* background-color: #ffffff !important;
    background-image: url('~@/assets/bg-strip-rotate.svg') !important;
    background-repeat: no-repeat !important;
    background-position: 99.5% 5% !important;
    background-size: 300px !important; */
    position:relative;

}

@keyframes strip-animate-1 {
    0%{background-position:70% 50%}
    50%{background-position:20% 50%}
    100%{background-position:70% 50%}
}
@keyframes strip-animate-2 {
    0%{background-position:20% 50%}
    50%{background-position:80% 50%}
    100%{background-position:20% 50%}
}
@keyframes strip-animate-3 {
    0%{background-position:0% 50%}
    50%{background-position:75% 50%}
    100%{background-position:0% 50%}
}
@keyframes strip-animate-4 {
    0%{background-position:100% 50%}
    50%{background-position:50% 50%}
    100%{background-position:100% 50%}
}
@keyframes strip-animate-5 {
    0%{background-position:70% 50%}
    50%{background-position:20% 50%}
    100%{background-position:70% 50%}
}
.strip{
  position:absolute;
  bottom:0;
  right:0;
  height:100%;
  width:330px;
  background-size: 300px;
  background-repeat: no-repeat;
}

.strip-1{
  background-image: url('~@/assets/strip-1.svg');
  animation: strip-animate-1 25s ease infinite;
  -webkit-animation: strip-animate-1 25s ease infinite;
  -moz-animation: strip-animate-1 25s ease infinite;
  -o-animation: strip-animate-1 25s ease infinite;

}

.strip-2{
  background-image: url('~@/assets/strip-2.svg');
  animation: strip-animate-2 15s ease infinite;
  -webkit-animation: strip-animate-2 15s ease infinite;
  -moz-animation: strip-animate-2 15s ease infinite;
  -o-animation: strip-animate-2 15s ease infinite;
}

.strip-3{
  background-image: url('~@/assets/strip-3.svg');
  animation: strip-animate-3 20s ease infinite;
  -webkit-animation: strip-animate-3 20s ease infinite;
  -moz-animation: strip-animate-3 20s ease infinite;
  -o-animation: strip-animate-3 20s ease infinite;
}
.strip-4{
  background-image: url('~@/assets/strip-4.svg');
  animation: strip-animate-4 30s ease infinite;
  -webkit-animation: strip-animate-4 30s ease infinite;
  -moz-animation: strip-animate-4 30s ease infinite;
  -o-animation: strip-animate-4 30s ease infinite;
}
.strip-5{
  background-image: url('~@/assets/strip-5.svg');
  animation: strip-animate-5 35s ease infinite;
  -webkit-animation: strip-animate-5 35s ease infinite;
  -moz-animation: strip-animate-5 35s ease infinite;
  -o-animation: strip-animate-5 35s ease infinite;
}

.text-color{
  background: linear-gradient(to right,#00b490, #472f92);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size:1.5rem;
}
</style>