<template>
    <v-card-actions class="text-caption" style="width:100%;position:absolute;bottom:0;left:0">
        <v-container v-if="!selectedDate">
            <v-row>
          <v-col col="6" class="pb-0 pt-0 text-no-wrap">
            <v-progress-circular indeterminate :size="14" :width="1.4" v-if="isLoading" class="mr-1"></v-progress-circular>
            <v-icon size="16" class="mr-1" v-on:click="refresh" v-show="!isLoading">mdi-reload</v-icon>
             {{lastUpdateStr}}
          </v-col>
          <v-col col="6" align="right" class="pb-0 pt-0 text-no-wrap" v-if="showLastData">
            <v-icon size="16" class="mr-1">mdi-timetable</v-icon>
            {{lastDataStr}}
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="selectedDate">
          <v-row>
          <v-col col="12" align="right" class="pb-0 pt-0 text-no-wrap" v-if="showLastData">
            <v-progress-circular indeterminate :size="14" :width="1.4" v-if="isLoading" class="mr-1"></v-progress-circular>
            <v-icon size="16" class="mr-1" v-show="!isLoading">mdi-calendar</v-icon>
            {{selectedDateStr}}
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
</template>

<script>
import {DateMixin} from '../DateMixin.js'

export default {
  mixins:[DateMixin],
  props: ["lastData","lastUpdate","isLoading","selectedDate"],
  data() {
    return {
    };
  },
  methods:{
      refresh: function() {
          this.$emit('refresh')
      }
  },
  computed:{
      lastUpdateStr() {
          if ( this.lastUpdate instanceof Date ) {
            return this.formatedDate(this.lastUpdate)
          }
          return "Updating..."
      },
      lastDataStr() {
          if ( this.lastData instanceof Date ) {
            return this.formatedDate(this.lastData)
          }
          return "-"
      },
      selectedDateStr() {
          if ( this.selectedDate instanceof Date ) {
            return this.formatedFullDate(this.selectedDate)
          }
          return "-"
      },
      showLastData() {
        return this.lastData instanceof Date
      }
  }
}
</script>