<template>
  <v-card outlined style="position:relative;padding-bottom:30px;">
    <v-card-title>
      <v-icon size="32" class="mr-2"> mdi-chart-box</v-icon>
      Weekly Consumption
    </v-card-title>
    <v-card-text>
      <canvas id="line-chart-4" ></canvas>
    </v-card-text>
    <last-update-component :lastUpdate="lastUpdate" :lastData="lastData" :isLoading="isLoading" :selectedDate="selectedDate" v-on:refresh="refresh"/>

  </v-card>
</template>

<script>
import { Chart, registerables } from "chart.js";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
import { ENMOService } from "../services";
import LastUpdateComponent from './LastUpdateComponent.vue';

Chart.register(...registerables, chartjsPluginAnnotation);
let myChart

export default {
  components:{
    LastUpdateComponent
  },
  props: {
    forceReload: String,
    siteId: String,
    selectedDate: Date
  },
  data() {
    return {
      gradient:null,
      lastUpdate:"",
      lastData:"",
      isLoading: true
    };
  },
  watch:{
    forceReload: async function() {
      if ( this.forceReload) {
        await this.refresh(this.forceReload)
        this.$emit('reloadCompleted')
      }
    }
  },
  methods:{
    formatNumber: (num)=> {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    refresh: async function(animate) {
      const [data, annotations]= await this.loadData()
      if (!data || !annotations) return
      myChart.data = data
      myChart.options.plugins.annotation.annotations = annotations
      if ( animate === "animate" ) {
        myChart.update()
      }else{
        myChart.update('none')
      }
    },
    loadData: async function() {
      this.lastUpdate = ""
      this.isLoading = true

      const dateDay = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      const nameDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      try{
        const rawDataWeekly = await ENMOService.getDataWeekly(this.siteId, this.selectedDate );
        if ( !rawDataWeekly || rawDataWeekly.code !== 0 ) {
          return [null,null]
        }

        this.lastData = new Date(rawDataWeekly.data.lastDataAt);
        const thisWeekArr = rawDataWeekly.data.thisWeek;
        const lastWeekArr = rawDataWeekly.data.lastWeek;
        const peak = rawDataWeekly.data.maxMonth.toFixed(2)> 1527000 ? (rawDataWeekly.data.maxMonth - 1500000).toFixed(2): rawDataWeekly.data.maxMonth.toFixed(2);

        const dayLabels =[]
        const thisWeekEnergy=[]
        const lastWeekEnergy=[]
        nameDays.forEach((day, index) => {
          const lastweek = lastWeekArr.find( d => dateDay[new Date(d.date).getDay()] === day ) || {}
          const thisweek = thisWeekArr.find( d => dateDay[new Date(d.date).getDay()] === day ) || {}
          
          dayLabels[index] = day;
          if ( thisweek.value ){
            thisWeekEnergy[index] = thisweek.value;
          }
          if ( lastweek.value ){
            lastWeekEnergy[index] = lastweek.value > 1500000 ? lastweek.value - 1500000: lastweek.value;
          }
        });
      
        const data = {
          labels: dayLabels,
          datasets: [
            {
              label: "This week",
              data: thisWeekEnergy,
              backgroundColor: this.gradient,
            },
            {
              label: "Last week",
              data: lastWeekEnergy,
              backgroundColor: "lightgray",
              borderColor: "lightgray",
            },
            {
              label: "Highest energy consumption",
              backgroundColor: "#FFB100",
            }
          ],
        };
        const annotations = {
              line1: {
                type: "line",
                scaleID: 'y',
                value: peak,
                borderColor: "#FFB100",
                borderWidth: 0.5,
                label: {
                  font:{size:9},
                  cornerRadius:3,
                  xPadding:5,
                  yPadding:2,
                  xAdjust:-15,
                  position:'start',
                  textAlign:'end',
                  backgroundColor: "#FFB100",
                  enabled: true,
                  content: this.formatNumber(peak),
                },
              },
            }
        this.lastUpdate = new Date()
        this.isLoading = false
        return [data, annotations]
      }catch(_) {
        this.isLoading = false
        return []
      }
    }
  },
  async mounted() {
    
    var ctx = document.getElementById(`line-chart-4`).getContext("2d");
    document.getElementById(`line-chart-4`).height = 160;
    this.gradient = ctx.createLinearGradient( 0, 300, 0, 20 );
    this.gradient.addColorStop(1, "#472F92");
    this.gradient.addColorStop(0, "#00B490");

    const [data, annotations] = await this.loadData()
    if (!data || !annotations) return
    myChart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: annotations
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            display: true,
          },
        },
        responsive: true,
        scales: {
          x: {},
          y: {
            title: {
              display: true,
              text: "Energy (kWh)",
            },
          },
        },
      },
    });

  },
};
</script>

<style>
.v-application p {
  margin: 0;
}
</style>
