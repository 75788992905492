<template>
  <v-card outlined style="position:relative; padding-bottom:48px;">
    <v-card-title>
      <v-icon size="32" class="mr-2"> mdi-flash-circle </v-icon>
          Today Consumption
    </v-card-title>
    <v-card-text class="pb-0 pl-5 pr-5">
      <v-row justify="space-between" no-gutters>
        <v-col cols="12" class="text-h4 text-no-wrap ">
            {{ todayConsumtion | formatNumber }} <span class="text-body-1">kWh</span>
        </v-col>
      </v-row>
      <v-row>
        <v-progress-linear
          dark
          class="rounded-pill"
          :value="progress"
          :color="barColor"
          height="20"
        >Level {{level}} ({{ progress }}%) 
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="16" class="pa-1" style="cursor: pointer;" v-bind="attrs"  v-on="on">mdi-information-outline</v-icon>
              </template>
              <span style="font-size:0.8rem">
                <v-icon x-small color="#00b490" >mdi-checkbox-blank-circle</v-icon> Level 1 (0-60%)<br />
                <v-icon x-small color="#ffb100" >mdi-checkbox-blank-circle</v-icon> Level 2 (61-80%)<br />
                <v-icon x-small color="#e04848" >mdi-checkbox-blank-circle</v-icon> Level 3 (81-100%)<br />
                <v-icon x-small color="#472f92" >mdi-checkbox-blank-circle</v-icon> Level 4 (> 100%)<br />
                <span v-if="lastMonth">Target = {{lastMonth | formatNumber }} kWh</span>
              </span>
          </v-tooltip>
        </v-progress-linear>
      </v-row>
      <v-row align="center">
        <v-col col="4" class="pb-1 font-weight-medium">
          Current Demand
          <p class="text-caption">{{ currentDemandTime }}</p>
        </v-col>
        <v-col cols="6" align="right" class="pb-1 font-weight-medium text-no-wrap">
          <p>{{ currentDemand | formatNumber}} <span class="text-body-2">kW</span></p>
        </v-col>
      </v-row>
      <v-row align="center" >
        <v-col col="4" class="pt-1 pb-2 font-weight-medium">
          Peak Demand
          <p class="text-caption">{{ peakDemandTime }}</p>
        </v-col>
        <v-col cols="6" align="right" class="pt-1 pb-2 font-weight-medium text-no-wrap">
          <p>{{ peakDemand | formatNumber}} <span class="text-body-2">kW</span></p>
        </v-col>
      </v-row >

      <v-row align="center" class="d-none d-md-flex" >
        <div style="height:100%"></div>
      </v-row>
    </v-card-text>
    <last-update-component :lastData="lastData" :lastUpdate="lastUpdate" :isLoading="isLoading" :selectedDate="selectedDate" v-on:refresh="loadData"/>
  </v-card>
</template>

<script>
import moment from "moment";
import { ENMOService } from "../services";
import LastUpdateComponent from './LastUpdateComponent.vue';

export default {
  components:{
    LastUpdateComponent
  },
  props: {
    forceReload: String,
    siteId: String,
    selectedDate: Date
  },
  data() {
    return {
      progress: 0,
      todayConsumtion: 0,
      currentDemand: 0,
      currentDemandTime: "",
      peakDemand: 0,
      peakDemandTime: "",
      lastMonth:1,
      lastData:"",
      lastUpdate: "",
      isLoading: true
    };
  },
  watch:{
    forceReload: async function() {
      if ( this.forceReload) {
        await this.loadData()
        this.$emit('reloadCompleted')
      }
    }
  },
  computed: {
    barColor() {
      const progress = this.progress
      if ( progress <= 60 ) {
        return "#00b490"
      }else if (progress <= 80 ) {
        return "#ffb100"
      }else if (progress <= 100 ) {
        return "#e04848"
      }else{
        return "#472f92"
      }
    },
    level() {
      const progress = this.progress
      if ( progress <= 60 ) {
        return "1"
      }else if (progress <= 80 ) {
        return "2"
      }else if (progress <= 100 ) {
        return "3"
      }else{
        return "4"
      }
    }
  },
  methods: {
    loadData: async function() {
      this.lastUpdate = ""
      this.isLoading = true

      try {
        const rawDataToday = await ENMOService.getDataToday(this.siteId, this.selectedDate );
        if ( !rawDataToday || rawDataToday.code !== 0 ) {
          return
        }

        this.lastData = new Date(rawDataToday.data.lastDataAt);
        const today = rawDataToday.data.today;
        this.lastMonth = rawDataToday.data.lastMonth;
        this.todayConsumtion = today || 0
        this.progress = this.lastMonth ? ((today / this.lastMonth) * 100).toFixed(2) : 100;
        this.currentDemand = rawDataToday
          ? rawDataToday.data.current.value
          : 0;
        this.peakDemand = rawDataToday
          ? rawDataToday.data.peak.value
          : 0;
        this.currentDemandTime = rawDataToday
          ? moment(rawDataToday.data.current.date).format("dddd, MMM DD, HH:mm")
          : "";
        this.peakDemandTime = rawDataToday
          ? moment(rawDataToday.data.peak.date).format("dddd, MMM DD, HH:mm")
          : "";
          this.lastUpdate = new Date()
          this.isLoading = false
        }catch(_) {
          this.isLoading = false
        }
    }
  },
  filters:{
    formatNumber: (num)=> {
      if ( num ) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }else{
        return "0.00"
      }
    }
  },
  async mounted() {
    await this.loadData()
  },
};
</script>

