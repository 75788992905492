<template>
  <v-card outlined style="position:relative;padding-bottom:48px;">
    <v-card-title>
    <v-icon size="32" class="mr-2"> mdi-flash-circle </v-icon>
    Monthly Consumption
    </v-card-title>
    <v-card-text class="pb-0 pl-5 pr-5">
      <v-row justify="space-between" no-gutters>
        <v-col cols="12" class="text-h4 text-no-wrap">
            {{ monthlyConsumtion | formatNumber }} <span class="text-body-1">kWh</span>
        </v-col>
      </v-row>
      <v-row>
        <v-progress-linear
          dark
          class="rounded-pill"
          :value="progress"
          :color="barColor"
          height="20"
        >Level {{ level }} ({{ progress }}%)
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="16" class="pa-1" style="cursor: pointer;" v-bind="attrs"  v-on="on">mdi-information-outline</v-icon>
              </template>
              <span style="font-size:0.8rem">
                <v-icon x-small color="#00b490" >mdi-checkbox-blank-circle</v-icon> Level 1 (0-60%)<br />
                <v-icon x-small color="#ffb100" >mdi-checkbox-blank-circle</v-icon> Level 2 (61-80%)<br />
                <v-icon x-small color="#e04848" >mdi-checkbox-blank-circle</v-icon> Level 3 (81-100%)<br />
                <v-icon x-small color="#472f92" >mdi-checkbox-blank-circle</v-icon> Level 4 (> 100%)<br />
                <span v-if="lastMonth">Target = {{lastMonth | formatNumber }} kWh</span>
              </span>
          </v-tooltip>
        </v-progress-linear>
      </v-row>
      <v-row align="center">
        <v-col col="8" class="pb-1 font-weight-medium">
          Highest Consumption
          <p class="text-caption">{{ highestConsumptionTime }}</p>
        </v-col>
        <v-col cols="4" align="right" class="pb-1 font-weight-medium text-no-wrap">
          <p>{{ highestConsumption | formatNumber}} <span class="text-body-2">kWh</span></p>
        </v-col>
      </v-row>
      <v-row align="center" >
        <v-col col="8" class="pt-1 pb-1 font-weight-medium">
          Lowest Consumption
          <p class="text-caption">{{ lowestConsumptionTime }}</p>
        </v-col>
        <v-col cols="4" align="right" class="pt-1 pb-1 font-weight-medium text-no-wrap">
          <p>{{ lowestConsumption | formatNumber}} <span class="text-body-2">kWh</span></p>
        </v-col>
      </v-row>
      <v-row align="center" >
        <v-col col="8" class="pt-1 pb-2 font-weight-medium">
          Demand charge
          <p class="text-caption">{{ demandChargeTime }}</p>
        </v-col>
        <v-col cols="4" align="right" class="pt-1 pb-2 font-weight-medium text-no-wrap">
          <p>{{ demandCharge | formatNumber}} <span class="text-body-2">kW</span></p>
        </v-col>
      </v-row>
    </v-card-text>
    <last-update-component :lastUpdate="lastUpdate" :lastData="lastData" :isLoading="isLoading" :selectedDate="selectedDate" v-on:refresh="loadData"/>
  </v-card>
</template>

<script>
import { Chart, registerables } from "chart.js";
import moment from "moment";
import { ENMOService } from "../services";
import LastUpdateComponent from './LastUpdateComponent.vue';

Chart.register(...registerables);

export default {
  components:{
    LastUpdateComponent
  },
  props: {
    forceReload: String,
    siteId: String,
    selectedDate: Date
  },
  data() {
    return {
      progress: 0,
      monthlyConsumtion: 0,
      highestConsumption: 0,
      lowestConsumption: 0,
      demandCharge: 0,
      lastMonth:0,
      highestConsumptionTime: "",
      lowestConsumptionTime: "",
      demandChargeTime: "",
      lastUpdate: "",
      lastData:"",
      isLoading: true,
    };
  },
  watch:{
    forceReload: async function() {
      if ( this.forceReload) {
        await this.loadData()
        this.$emit('reloadCompleted')
      }
    }
  },
  computed: {
    barColor() {
      const progress = this.progress
      if ( progress <= 60 ) {
        return "#00b490"
      }else if (progress <= 80 ) {
        return "#ffb100"
      }else if (progress <= 100 ) {
        return "#e04848"
      }else{
        return "#472f92"
      }
    },
    level() {
      const progress = this.progress
      if ( progress <= 60 ) {
        return "1"
      }else if (progress <= 80 ) {
        return "2"
      }else if (progress <= 100 ) {
        return "3"
      }else{
        return "4"
      }
    }
  },
  filters:{
    formatNumber: (num)=> {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
  methods:{
    loadData: async function() {
      this.lastUpdate = ""
      this.isLoading = true
      try{
        let rawDataMonthly = await ENMOService.getDataMonthly(this.siteId, this.selectedDate );
        if ( !rawDataMonthly || rawDataMonthly.code !== 0 ) {
          return
        }
        let thisMonth = rawDataMonthly.data.thisMonth;
        this.lastMonth = rawDataMonthly.data.lastMonth;
        this.lastData = new Date(rawDataMonthly.data.lastDataAt);
        this.monthlyConsumtion = thisMonth;
        this.progress = this.lastMonth
          ? ((thisMonth / this.lastMonth) * 100).toFixed(2)
          : 100;
        this.level1 = this.progress;
        this.highestConsumption = rawDataMonthly.data.highest.value;
        this.lowestConsumption = rawDataMonthly.data.lowest.value;
        this.demandCharge = rawDataMonthly.data.demandCharge.value;
        this.highestConsumptionTime = moment(
          rawDataMonthly.data.highest.date
        ).format("dddd, MMM DD");
        this.lowestConsumptionTime = moment(rawDataMonthly.data.lowest.date).format(
          "dddd, MMM DD"
        );
        this.demandChargeTime = moment(rawDataMonthly.data.demandCharge.date).format("dddd, MMM DD, HH:mm");
        this.lastUpdate = new Date()
        this.isLoading = false
      }catch(_) {
        this.isLoading = false
      }
    }
  },
  async mounted() {
    await this.loadData()
  },
};
</script>

<style>
.v-application p {
  margin: 0;
}
</style>
