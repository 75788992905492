<template>
  <v-app class="app-bg">
    <banpu-toolbar v-on:siteChanged="siteChanged" v-on:dateChanged="dateChanged"/>
    <div class="toolbar-line">
    </div>
    <v-main>
      <router-view :site="site" :selectedDate="selectedDate"/>
    </v-main>
    <v-footer padless color="rgb(0, 0, 0, 0.1)">
      <v-card class="flex" flat tile color="rgb(0, 0, 0, 0)">
        <v-card-text class="py-2 text-gray-400 text-center">
          <strong>© Copyright 2021 Banpu NEXT Co., Ltd.</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import BanpuToolbar from "./components/BanpuToolbar.vue";
export default {
  name: "App",
  components: {
    BanpuToolbar,
  },
  data() {
    return {
      site:{_id:""},
      selectedDate:undefined
    }
  },
  methods:{
    siteChanged:function (siteId ) {
      this.site = {_id:siteId}
    },
    dateChanged(date) {
      this.selectedDate = date
    }
  }
};
</script>

<style>
  @font-face {
    font-family: Gotham;
    src: local("Gotham"), url(./fonts/Gotham-Book.woff2) format("woff2");
    font-weight: bold;
  }
  @font-face {
    font-family: Gotham;
    src: local("Gotham"), url(./fonts/Gotham-Bold.woff2) format("woff2");
    font-weight:bolder
  }
  @font-face {
    font-family: Gotham;
    src: local("Gotham"), url(./fonts/Gotham-Light.woff2) format("woff2");
    font-weight: lighter
  }
  @font-face {
    font-family: Gotham;
    src: local("Gotham"), url(./fonts/Gotham-Medium.woff2) format("woff2");
  }
  .v-application {
    font-family: "Gotham", "Roboto", sans-serif;
  }
  .v-application .text-caption{
    font-family: "Gotham", "Roboto", sans-serif !important;
  }
  
@keyframes bar-animate {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
  .toolbar-line {
    background: linear-gradient(90deg, #00b490, #472f92, #00b490, #472f92);
    background-size: 400% 400%;
    width:100%;
    height:3px;
    -webkit-animation: bar-animate 26s ease infinite;
    -moz-animation: bar-animate 26s ease infinite;
    -o-animation: bar-animate 26s ease infinite;
    animation: bar-animate 26s ease infinite;
  }
  .app-bg{
    background-color: #e5e7eb !important;
    /* background-image: url('~@/assets/bg-strip-rotate.svg') !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: right bottom !important;
    background-size: 40% !important; */
  }
  .footer-bg {
    background-color: #e5e7eb !important;
  }
</style>