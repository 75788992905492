import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import user from "./user";
import enmo from "./enmo";

Vue.use(Vuex);

const modules = {
  user,
  enmo
};

const vuexStorage = new VuexPersist({
  key: "storageVuex",
  storage: window.localStorage,
  // reducer: (state) => ({
  //   user: state.user,
  // }),
});

export default new Vuex.Store({
  modules,
  plugins: [vuexStorage.plugin],
});
