const mutations = {
  SET_DAILY: (state, daily) => {
    state.daily = daily;
  },
  SET_TODAY: (state, today) => {
    state.today = today;
  },
  SET_WEEKLY: (state, weekly) => {
    state.weekly = weekly;
  },
  SET_MONTHLY: (state, monthly) => {
    state.monthly = monthly;
  },
  SET_SANKEY: (state, sankey) => {
    state.sankey = sankey;
  },
};

export default mutations;
