import service from "../api.service";
const url = "data";

const ENMO = {
  getDataDaily(siteId,date) {
    const dateStr = date?"&date="+date.toISOString():""
    return service.get(`${url}/daily?siteId=${siteId}${dateStr}`);
  },
  getDataToday(siteId,date) {
    const dateStr = date?"&date="+date.toISOString():""
    return service.get(`${url}/today?siteId=${siteId}${dateStr}`);
  },
  getDataWeekly(siteId,date) {
    const dateStr = date?"&date="+date.toISOString():""
    return service.get(`${url}/weekly?siteId=${siteId}${dateStr}`);
  },
  getDataMonthly(siteId,date) {
    const dateStr = date?"&date="+date.toISOString():""
    return service.get(`${url}/monthly?siteId=${siteId}${dateStr}`);
  },
  getDataSankey(siteId,date,month) {
    const dateStr = date?"&date="+date.toISOString():""
    const monthStr = "&month="+month
    return service.get(`${url}/sankey?siteId=${siteId}${monthStr}${dateStr}`);
  },
  getDataRanking(date) {
    const dateStr = date?"?date="+date.toISOString():""
    return service.get(`${url}/ranking${dateStr}`);
  }
};

export default ENMO;
