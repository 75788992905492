<template>
  <v-card outlined style="position:relative;padding-bottom:30px;">
    <v-card-title class="pb-2">
      <v-row no-gutters justify="space-between">
        <v-col align-self="end">
          <v-icon size="32" >mdi-trophy-award </v-icon>
          Ranking
        </v-col>
        <v-col align="right">
          <v-btn-toggle 
            v-model="showView" 
            dense 
            mandatory
            borderless
            color="#00B490"
            class="mr-1"
            >
            <v-btn style="font-size:0.8rem;">Bay</v-btn>
            <v-btn style="font-size:0.8rem;">Staff</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="pb-0" >
    <v-tabs-items v-model="showView">
      <v-tab-item>
        <v-card outlined v-for="(rank,index) in rankBay" :key="rank.id" class="mt-6 mb-6 ma-2" :style="'border-color: '+getBorderColer(index)+';'">
        <v-card-text class="d-flex align-center justify-center pa-2" :class="{no1frame:index===0}">
          <span v-if="index==0" style="position: relative;width:68px;height:64px;" >
            <img :src="getImgUrl('1.0')" width="64" class="mr-2 no1" :alt="'Number '+(index+1)" />
            <img :src="getImgUrl('1.1')" width="64" class="mr-2 no1 no11" :alt="'Number '+(index+1)" />
            <img :src="getImgUrl('1.2')" width="64" class="mr-2 no1 no12" :alt="'Number '+(index+1)" />
            <img :src="getImgUrl('1.3')" width="64" class="mr-2 no1 no13" :alt="'Number '+(index+1)" />
          </span>
          <img v-else :src="getImgUrl(index+1)" width="64" class="mr-2" :alt="'Number '+(index+1)"/>
          <div style="text-overflow: ellipsis;overflow: hidden;">
            <div style="font-size:1.2rem;" class="font-weight-medium">{{rank.name }}<br />
              <div style="font-size:0.7rem;" v-if="index !== 0 || true">{{ rank.energyBay | formatNumber }} kWh/bay • {{rank.bay}} bays</div>
            </div>
          </div>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card outlined v-for="(rank,index) in rankStaff" :key="rank.id" class="mt-6 mb-6 ma-2" :style="'border-color: '+getBorderColer(index)+';'">
          <v-card-text class="d-flex align-center justify-center pa-2" :class="{no1frame:index===0}">
          <span v-if="index==0" style="position: relative;width:68px;height:64px;" >
            <img :src="getImgUrl('1.0')" width="64" class="mr-2 no1" :alt="'Number '+(index+1)" />
            <img :src="getImgUrl('1.1')" width="64" class="mr-2 no1 no11" :alt="'Number '+(index+1)" />
            <img :src="getImgUrl('1.2')" width="64" class="mr-2 no1 no12" :alt="'Number '+(index+1)" />
            <img :src="getImgUrl('1.3')" width="64" class="mr-2 no1 no13" :alt="'Number '+(index+1)" />
          </span>
          <img v-else :src="getImgUrl(index+1)" width="64" class="mr-2" :alt="'Number '+(index+1)"/>
          <div style="text-overflow: ellipsis;overflow: hidden;">
            <div style="font-size:1.2rem;" class="font-weight-medium">{{rank.name }}<br />
              <div style="font-size:0.7rem;" v-if="index !== 0 || true">{{ rank.energyStaff | formatNumber }} kWh/staff • {{rank.staff}} staffs</div>
            </div>
          </div>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    </v-card-text>
    <last-update-component :lastUpdate="lastUpdate" :lastData="lastData" :isLoading="isLoading" :selectedDate="selectedDate" v-on:refresh="loadData"/>
  </v-card>
</template>

<script>
import LastUpdateComponent from './LastUpdateComponent.vue';
import { ENMOService } from "../services";

export default {
  components:{
    LastUpdateComponent
  },
  props: {
    forceReload: String,
    selectedDate: Date
  },
  data() {
    return {
      lastUpdate: "",
      lastData:"",
      isLoading: true,
      rankBay:[],
      rankStaff:[],
      showView:0
    };
  },
  watch:{
    forceReload: async function() {
      if ( this.forceReload) {
        await this.loadData()
        this.$emit('reloadCompleted')
      }
    }
  },
  methods:{
    loadData: async function() {
      this.lastUpdate = ""
      this.isLoading = true

      let rawDataRanking = await ENMOService.getDataRanking(this.selectedDate);
      if ( !rawDataRanking || rawDataRanking.code !== 0 || ! rawDataRanking.data ) {
        return
      }
      this.rankStaff = rawDataRanking.data.rankStaff
      this.rankBay = rawDataRanking.data.rankBay
      this.lastData = new Date(rawDataRanking.data.lastDataAt);

      this.lastUpdate = new Date()
      this.isLoading = false
    },
    getImgUrl(img) {
      const images = require.context('../assets/', false, /\.svg$/)
      return images('./rank-' + img + ".svg")
    },
    getBorderColer(index){
      switch(index) {
        case 0:
          return "#EAA524"
        case 1:
          return "#AAB9CC"
        case 2:
          return "#E5C6BD"
      }
      
    }
  },
  async mounted() {
    await this.loadData()
  },
  filters:{
    shorten(value) {
      if ( value.length > 15) {
        return value.substr(0,12)+"..."
      }else{
        return value
      }
    },
    formatNumber: (num)=> {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
.no1{
  position: absolute; 
  top:0; 
  left:0;
}
.no11 {
  animation-duration: 0.6s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.no12 {
  animation-duration: 0.6s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 0.4s;
}
/* .no1frame:hover .no13  */
.no13 {
  animation-duration: 0.6s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 0.7s;
}
</style>
