<template>
  <v-container fluid>
    <v-row>
      <v-col lg="4" sm="12" md="6" xl="3">
        <daily-consume-energy-accumulation
          :forceReload="forceReload"
          :siteId="siteId"
          :selectedDate="selectedDate"
          v-on:reloadCompleted="reloadCompleted"
          class="fill-height"
        />
      </v-col>
      <v-col lg="4" sm="12" md="6" xl="3">
        <today-consumption class="fill-height" 
          :forceReload="forceReload"
          :siteId="siteId"
          :selectedDate="selectedDate"
          v-on:reloadCompleted="reloadCompleted" />
      </v-col>
      <v-col lg="4" sm="12" md="6" xl="3">
        <weekly-consumption class="fill-height" 
          :forceReload="forceReload" 
          :siteId="siteId"
          :selectedDate="selectedDate"
          v-on:reloadCompleted="reloadCompleted" />
      </v-col>
      <v-col lg="3" sm="12" md="6" xl="3" >
        <monthly-consumption class="fill-height" 
          :forceReload="forceReload"
          :siteId="siteId"
          :selectedDate="selectedDate"
          v-on:reloadCompleted="reloadCompleted" />
      </v-col>
      <v-col lg="6" sm="12" md="8" xl="9" >
        <energy-consumption class="fill-height" 
          :forceReload="forceReload"
          :siteId="siteId"
          :selectedDate="selectedDate"
          v-on:reloadCompleted="reloadCompleted" />
      </v-col>
      <v-col lg="3" sm="12" md="4" xl="3" >
          <ranking class="fill-height" 
            :forceReload="forceReload"
            :selectedDate="selectedDate"
            v-on:reloadCompleted="reloadCompleted" />
      </v-col>


    </v-row>
  </v-container>
</template>
<script>
import DailyConsumeEnergyAccumulation from "../components/DailyConsumeEnergyAccumulation.vue";
import TodayConsumption from "../components/TodayConsumption.vue";
import WeeklyConsumption from "../components/WeeklyConsumption.vue";
import MonthlyConsumption from "../components/MonthlyConsumption.vue";
import EnergyConsumption from "../components/EnergyConsumption.vue";
import Ranking from "../components/Ranking.vue";

export default {
  props: {
    site: Object,
    selectedDate: Date
  },
  components: {
    DailyConsumeEnergyAccumulation,
    TodayConsumption,
    WeeklyConsumption,
    MonthlyConsumption,
    EnergyConsumption,
    Ranking
  },
  data() {
    return {
      timer:"",
      forceReload:"",
      siteId:""
    };
  },
  methods:{
    reloadCompleted: function() {
      this.forceReload = ""
    },
    startTimer() {
      this.timer = setInterval(() => {
        const date = new Date()
        if ( date.getSeconds() === 0 ) {
          this.forceReload = "none"
        }
      }, 1000);
    },
    clearTimer() {
      clearInterval(this.timer);
    }
  },
  watch:{
    site:{
      handler(n,o) {
        this.siteId = n._id
        if (o._id) {
          this.forceReload = "animate"
        }
      },
      deep:true
    },
    selectedDate() {
      if ( this.selectedDate ){
        this.clearTimer()
      }else{
        this.startTimer()
      }
      this.forceReload = "animate"
    }
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.clearTimer();
  }
};
</script>
