import { ENMOService } from "../../services";

const actions = {
  async getDataDaily({ commit }) {
    const daily = await ENMOService.getDataDaily;
    commit("SET_DAILY", daily);
    return daily;
  },
  async getDataToday({ commit }) {
    const today = await ENMOService.getDataToday;
    commit("SET_TODAY", today);
    return today;
  },
  async getDataWeekly({ commit }) {
    const weekly = await ENMOService.getDataWeekly;
    commit("SET_WEEKLY", weekly);
    return weekly;
  },
  async getDataMonthly({ commit }) {
    const monthly = await ENMOService.getDataMonthly;
    commit("SET_MONTHLY", monthly);
    return monthly;
  },
  async getDataSankey({ commit }) {
    const sankey = await ENMOService.getDataSankey;
    commit("SET_SANKEY", sankey);
    return sankey;
  },
};

export default actions;
