<template>
  <v-container fluid> </v-container>
</template>
<script>

export default {
  async created() {
    const otc = this.$route.query.otc
    const siteId = this.$route.query.siteId
    this.$hideToolbar = true
    if ( otc && siteId ) {
      await this.$store.dispatch("user/token", {
        watticOtc: otc,
        siteId: siteId
      });
      this.$router.push("/");
    }else{
      this.$router.push("/login");
    }
  },
};
</script>
