<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-form @submit.prevent="login" ref="form" lazy-validation >
          <v-card class="pa-2">
            <v-card-title class="headline"><v-icon large class="mr-2">mdi-account</v-icon> Sign in to your account</v-card-title>
            <v-card-text>
              <v-alert dense type="error" text v-show="apiError">
                {{apiError}}
              </v-alert>
              <v-text-field
                class="pl-4 pr-4"
                label="Email"
                v-model="email"
                auto-grow
                :rules="emailRules"
                :disabled="loading"
                color="#00b490"
                required
              />
              <v-text-field
                class="pl-4 pr-4"
                label="Password"
                v-model="password"
                auto-grow
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :disabled="loading"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                color="#00b490"
              />
            </v-card-text>
            <v-card-actions class="align-right d-flex flex-column">
              <v-btn
                type="submit"
                depressed
                dark
                color="#472f92"
                :loading="loading"
              >
                Sign in
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {AuthService} from '../services'

export default {
  data: () => ({
    valid: false,
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    showPassword: false,
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Password must be 8 characters or more",
    ],
    loading: false,
    loadpagefinish: false,
    apiError:""
  }),
  async mounted() {
    if ( this.$store.state.user.accessToken != null ){
      this.$router.push({path:"/"}).catch(() => {});
    }
  },
  methods: {
    async login() {
      this.loading = true;
      if ( !this.email || ! this.password) {
          return
      }
      const user = await AuthService.login({
          email: this.email,
          password: this.password,
      });
      if ( user.code !== 0 ) {
          this.apiError = user.message
      }else{
        this.$store.dispatch("user/login",user.data.accessToken);
        this.$router.push({path:"/"}).catch(() => {});
      }
    
      this.loading = false;
    },
  },
};
</script>
