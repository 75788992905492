export const DateMixin = {

    methods:{
        formatedDate: function(date)  {
            return  ("00" + date.getDate()).slice(-2)+ "/" +
            ("00" + (date.getMonth() + 1)).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
        },
        formatedFullDate: function(date)  {
            return ("00" + date.getDate()).slice(-2) + "/" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
            date.getFullYear()
        }
    }
}