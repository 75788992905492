import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Test from "../views/Test.vue";
import Wattic from "../views/Wattic.vue"
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiredAuthorization: true,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/wattic",
    name: "Wattic",
    component: Wattic,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  
  const isRequiredAuthorization = to.matched.some(
    (record) => record.meta.requiredAuthorization
  );
  let loggedIn = store.state.user.accessToken;
  
  if (!loggedIn && isRequiredAuthorization) {
    return next({
      path: "/login",
    });
  }
  return next();
});
export default router;
