import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Chart } from "chart.js";
// import Vuelidate from "vuelidate";
Chart.defaults.font.family = 'Gotham';
Chart.defaults.font.size = 10
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$hideToolbar = false
// Vue.use(Vuelidate);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
