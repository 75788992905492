<template>
  <v-card outlined style="position:relative;padding-bottom:30px;">
    <v-card-title class="pb-2">
      <v-row no-gutters justify="space-between">
        <v-col align-self="end" cols="12" sm="9" class="pb-2">
          <v-icon size="32" >mdi-chart-box </v-icon>
          Energy Balance Chart
        </v-col>
        <v-col align="right" cols="9" sm="3" class="pb-2">
          <v-btn-toggle 
            v-model="isMonth" 
            mandatory
            dense 
            borderless
            color="#00B490"
            class="mr-1"
            >
            <v-btn style="font-size:0.8rem;">DAY</v-btn>
            <v-btn style="font-size:0.8rem;">MONTH</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <canvas id="chart-sankey" style="max-height: 400px;"></canvas>
    </v-card-text>
    <last-update-component :lastUpdate="lastUpdate" :lastData="lastData" :isLoading="isLoading" :selectedDate="selectedDate" v-on:refresh="loadData"/>
  </v-card>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { SankeyController, Flow } from "chartjs-chart-sankey";
import { ENMOService } from "../services";
import LastUpdateComponent from './LastUpdateComponent.vue';

Chart.register(...registerables, SankeyController, Flow);
let myChart

export default {
  components:{
    LastUpdateComponent
  },
  props: {
    forceReload: String,
    siteId: String,
    selectedDate: Date
  },
  data() {
    return {
      lastUpdate:"",
      lastData:"",
      isLoading: true,
      isMonth:0
    };
  },
  watch:{
    forceReload: async function() {
      if ( this.forceReload ) {
        await this.refresh(this.forceReload)
        this.$emit('reloadCompleted')
      }
    },
    isMonth: async function() {
      await this.refresh("animate")
    }
  },
  methods:{
    refresh: async function(animate) {
      const data= await this.loadData()
      if (!data ) return
      myChart.data = data
      if ( animate === "animate" ) {
        myChart.update()
      }else{
        myChart.update('none')
      }
      
    },
    loadData: async function() {
      this.lastUpdate = ""
      this.isLoading = true
      try{
        const rawDataSankey = await ENMOService.getDataSankey(this.siteId, this.selectedDate, this.isMonth);
        if ( !rawDataSankey || rawDataSankey.code !== 0 ) {
          return null
        }
        const sankey = rawDataSankey.data.sankey.map( s => { return {...s, flow: +s.flow.toFixed(2) || 0 }});
        this.lastData = new Date(rawDataSankey.data.lastDataAt);
        const data = {
          datasets: [
            {
              data: sankey,
              colorFrom: (c) => this.getColor(c.dataset.data[c.dataIndex].from),
              colorTo: (c) => this.getColor(c.dataset.data[c.dataIndex].to),
              borderWidth: 0,
              nodeWidth:15
            },
          ],
        }
        this.lastUpdate = new Date()
        this.isLoading = false
        return data
      }catch(_) {
        this.isLoading = false
      }
    },
    getColor: function (name) {
      const colors = {
        "Office": "#4B86B7",
        "Service Center": "#B15DFF",
        "Air Condition":"#04C4F2",
        "Hoist":"#B15DFF",
        "Air Compressor":"#B78D4B",
        "Battery Chargers":"#A65A2E",
        "Emergency Loads":"#FF6859",
        "Lighting and Outlet":"#F6ED4C",
        "Water Pumps":"#034ECC",
        "Others":"#85C201",

        "HQ":"#ffbd43",
        "BangYai":"#ffbd43",
        "Srisaman":"#ffbd43",

        "Sunee 1":"#e04848",
        "MDB 2.2": "#e04848",

        "Zone": "#B15DFF",
        "Melt Shop": "#4B86B7",
        "Other Utility": "#04C4F2",
        "Air Loop A Cabinet": "#B78D4B",
        "Air Loop B Cabinet": "#F6ED4C",
        "Cutting Line A": "#FF6859",
      };
      return colors[name] || "#00B490";
    }
  },
  mounted: async function () {
    const ctx = document.getElementById("chart-sankey").getContext("2d");
    const data = await this.loadData(this.siteId)
    if (!data ) return
    myChart = new Chart(ctx, {
      type: "sankey",
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        
        scales: {
						y: {
							type: 'linear',
						},
						x: {
							type: 'linear',
              
						}
					}
      },
    });
  },
};
</script>
