import { ProfileService,AuthService } from "../../services";
const actions = {
  async login({ commit }, accessToken) {
    commit("SET_ACCESS_TOKEN", accessToken);
  },
  async token({ commit }, data) {
    const user = await AuthService.token(data);
    commit("SET_USER", user);
    if (user && user.data && user.data.accessToken) {
      commit("SET_ACCESS_TOKEN", user.data.accessToken);
    }    
    return user;
  },
  async logout({ commit }) {
    await ProfileService.logout();
    commit("RESET_USER");
  },
  async clear({ commit }) {
    commit("RESET_USER");
  },
  refresh({commit}) {
    commit("REFRESH");
  }
};

export default actions;
