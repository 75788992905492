<template>
  <div>
    <ranking />
  </div>
</template>
<script>
import Ranking from "../components/Ranking.vue";
Ranking;
export default {
  components: {
    Ranking,
  },
};
</script>
