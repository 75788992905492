<template>
  <v-card outlined style="position:relative;padding-bottom:30px;">
    <v-card-title class="text-no-wrap">
      <v-icon size="32" class="mr-2">mdi-chart-box</v-icon>
      Daily Consumption Profile
    </v-card-title>
    <v-card-text >
      <canvas id="line-chart-1"></canvas>

        <v-row no-gutters>
          <v-col cols="4" class="text-no-wrap offset-4" align="right">
            <span class="text-caption">Today</span>
          </v-col>
          <v-col cols="4" class="text-no-wrap" align="right">
            <span class="text-caption">7 days ago</span>
          </v-col>
          </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="text-no-wrap">
            Current Demand
          </v-col>
          <v-col
            cols="4"
            align="right"
            class="font-weight-medium text-no-wrap"
          >
            {{ currentDemandToday }} kW
          </v-col>
          <v-col
            cols="4"
            align="right"
            class="font-weight-medium text-no-wrap"
          >
            {{ currentDemandAgo }} kW
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4"> Peak Demand </v-col>
          <v-col
            cols="4"
            align="right"
            class="font-weight-medium text-no-wrap"
          >
            <span class="red--text">{{ peakDemandToday }} kW </span>
          </v-col>
          <v-col
            cols="4"
            align="right"
            class="font-weight-medium text-no-wrap"
          >
            {{ peakDemandAgo }} kW
          </v-col>
        </v-row>
    </v-card-text>
    <last-update-component :lastData="lastData" :lastUpdate="lastUpdate" :isLoading="isLoading" :selectedDate="selectedDate" v-on:refresh="refresh"/>

  </v-card>
</template>

<script>
import { Chart, registerables } from "chart.js";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
import moment from "moment";
import { ENMOService } from "../services";
import LastUpdateComponent from './LastUpdateComponent.vue';

Chart.register(...registerables, chartjsPluginAnnotation);
let myChart

export default {
  components:{
    LastUpdateComponent
  },
  props: {
    forceReload: String,
    siteId: String,
    selectedDate: Date
  },
  data() {
    return {
      currentDemandToday: 0,
      currentDemandAgo: 0,
      peakDemandToday: 0,
      peakDemandAgo: 0,
      gradient: null,
      lastData:"",
      lastUpdate: "",
      isLoading: true
    };
  },
  watch:{
    forceReload: async function() {
      if ( this.forceReload) {
        await this.refresh(this.forceReload)
        this.$emit('reloadCompleted')
      }
    }
  },
  methods: {
    refresh: async function(animate) {
      const [data, annotations]= await this.loadData()
      if (!data || !annotations) return
      myChart.data = data
      myChart.options.plugins.annotation.annotations = annotations
      if ( animate === "animate" ) {
        myChart.update()
      }else{
        myChart.update('none')
      }
    },
    formatNumber: (num) => {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    loadData: async function() {
      this.lastUpdate = ""
      this.isLoading = true
      const hourLabel = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
      const minuteLabel = ['00','15','30','45']
      try {
        let rawDataDaily = await ENMOService.getDataDaily(this.siteId, this.selectedDate );
        if ( !rawDataDaily || rawDataDaily.code !== 0 ) {
          return [null,null]
        }
            const todayArr = rawDataDaily.data.today;
            const agoArr = rawDataDaily.data.lastWeek;
            const currentToday = rawDataDaily.data.current.today;
            const currentAgo = rawDataDaily.data.current.lastWeek;
            this.lastData = new Date(rawDataDaily.data.lastDataAt);
            this.currentDemandToday = currentToday
              ? this.formatNumber(currentToday.toFixed(2))
              : 0;
            this.currentDemandAgo = currentAgo
              ? this.formatNumber(currentAgo.toFixed(2))
              : 0;
            const maxMonth = rawDataDaily.data.maxMonth
              ? rawDataDaily.data.maxMonth.toFixed(2)
              : 0;
            const peakToday = rawDataDaily.data.peak.today;
            const peakAgo = rawDataDaily.data.peak.lastWeek;
            this.peakDemandToday = peakToday
              ? this.formatNumber(peakToday.toFixed(2))
              : 0;
            this.peakDemandAgo = peakAgo ? this.formatNumber(peakAgo.toFixed(2)) : 0;

            let i = 0;
            const hourLabels =[]
            const dailyEnergy=[]
            const agoEnergy=[]
            for ( const hour of hourLabel) {
              for ( const min of minuteLabel) {
                const index = hour+":"+min
                const today = todayArr.find( d => moment(d.date).format("HH:mm") === index ) || {}
                const lastWeek = agoArr.find( d => moment(d.date).format("HH:mm") === index ) || {}

                hourLabels[i] = index
                if ( today.value ) {
                  dailyEnergy[i] = today.value;
                }
                if ( lastWeek.value ) {
                  agoEnergy[i] = lastWeek.value;
                }
                i++
              }
            }
        const data = {
                  labels: hourLabels,
                  datasets: [
                    {
                      label: "Today",
                      data: dailyEnergy,
                      backgroundColor: this.gradient
                    },
                    {
                      label: "7 days ago",
                      data: agoEnergy,
                      backgroundColor: "lightgray",
                      borderColor: "lightgray",
                    },
                    {
                      label: "Demand charge",
                      backgroundColor: "red",
                    },
                  ],
                };
          const annotations = {
                line1: {
                  type: "line",
                  scaleID: 'y',
                  value: maxMonth,
                  borderColor: "red",
                  borderWidth: 0.5,
                  label: {
                    font: { size: 9 },
                    cornerRadius: 3,
                    xPadding: 5,
                    yPadding: 2,
                    xAdjust: -15,
                    position: "start",
                    textAlign: "end",
                    backgroundColor: "red",
                    enabled: true,
                    content: this.formatNumber(maxMonth),
                  }
                }
              }
        this.lastUpdate = new Date()
        this.isLoading = false
        return [data, annotations]
      }catch(error) {
        this.isLoading = false
        return []
      }
    }
  },
  async mounted() {
    const ctx = document.getElementById(`line-chart-1`).getContext("2d");
    document.getElementById(`line-chart-1`).height = 130;

    this.gradient = ctx.createLinearGradient( 0, 200, 0, 20 );
    this.gradient.addColorStop(1, "#472F92");
    this.gradient.addColorStop(0, "#00B490");

    const [data, annotations] = await this.loadData()
    if (!data || !annotations) return

    myChart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: annotations ,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 15,
            },
          },
          y: {
            stacked: false,
            title: {
              display: true,
              text: "Power (kW)",
            },
          },
        },
      },
    });
  }
};
</script>
