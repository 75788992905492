import axios from "axios";
import router from '../router'
import store from '../store'

const http = new axios.create({
  baseURL: process.env.VUE_APP_API_URI,
});
// Request interceptor for API calls
http.interceptors.request.use(
  async (config) => {
    let vuex = JSON.parse(localStorage.getItem("storageVuex"));
    let api_key = process.env.VUE_APP_API_KEY
    let accessToken = vuex ? vuex.user.accessToken : "null";
    config.headers = {
      'x-api-key' : api_key,
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Response interceptor for API calls
http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (error) {
    if ( error.response && error.response.status === 401) {
      store.dispatch("user/clear");
      router.push('/login').catch(()=>{})
    }else{
      return Promise.reject(error.response.data);
    }
  }
);
// Custom method
const httpMethod = {
  async get(url) {
    return await http.get(url);
  },
  async post(url, data = {}) {
    return await http.post(url, data);
  },
  async put(url, data = null) {
    return await http.put(url, data);
  },
  async patch(url, data = null) {
    return await http.patch(url, data);
  },
  async delete(url) {
    return await http.delete(url);
  },
};

export default httpMethod;
