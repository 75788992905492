import service from "../api.service";
const url = "profile";

const Profile = {
  profile() {
    return service.get(`${url}`);
  },
  switchSite(data) {
    return service.patch(`${url}/switchSite`,data);
  },
  changePassword(data) {
    return service.patch(`${url}/password`,data);
  },
  logout() {
    return service.post(`${url}/logout`);
  },
};

export default Profile;
